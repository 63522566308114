import { JSX, Suspense } from "solid-js";
import { Spinner } from "./icons";

export default function SuspenseWrap(props: {
  class?: string;
  children: JSX.Element;
}) {
  return (
    <Suspense
      fallback={
        <div class="text-center my-16">
          <Spinner size={10} class="inline-block" />
        </div>
      }
    >
      <div class={props.class}>{props.children}</div>
    </Suspense>
  );
}
